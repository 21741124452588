$(document).ready(function () {
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".header-bottom").fadeIn(function () {
			$(".header-bottom").css({
				display: "flex",
			});
		});
	}

	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
	masonryInit();
	fileInput();
});

function fileInput() {
	$(".attachment-group").on("click", function () {
		$(".wpcf7-file").change(function () {
			var filename = $(this).val().split("\\").pop();
			if (filename != "")
				$(this).parents(".attachment-group-file").addClass("is-open");

			$(".file-list .file-name").html(
				'<div class="input-value">' + filename + "</div>"
			);
		});
	});
	$(".attachment-group-file .remove-file").on("click", function () {
		$(".attachment-group-file").removeClass("is-open");
		$(".file-name .input-value").empty();
		$(".attachment-group .wpcf7-file").val("");
	});
	// if ($(".top-banner .title").length) {
	// 	let titleColor= $(".top-banner .title").attr('data-color');
	// 	$(".top-banner .title").css({
	// 		"color" : titleColor
	// 	})
	// }
	// if ($(".top-banner .eyebrow ").length) {
	// 	let eyeColor= $(".top-banner .eyebrow ").attr('data-color');
	// 	$(".top-banner .eyebrow ").css({
	// 		"color" : eyeColor
	// 	})
	// }
}

function masonryInit() {
	var $grid = $(".grid-masonry").masonry({
		itemSelector: ".grid-item",
	});
}

function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".nav-primary-menu").toggleClass("open-menu");
		$hamburger.toggleClass("is-active");
	});
	document
		.querySelector(".search-toggle")
		.addEventListener("click", function (event) {
			document
				.querySelector(".search-overlay")
				.classList.toggle("active");
			$(".search-toggle").toggleClass("icon-change");
		});
	document.addEventListener("click", function (event) {
		if (
			!event.target.closest(".search-toggle") &&
			!event.target.closest(".search-overlay")
		) {
			document
				.querySelector(".search-overlay")
				.classList.remove("active");
			document
				.querySelector(".search-toggle")
				.classList.remove("icon-change");
		}
	});
	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-next",
			prevEl: ".primary-banner .nav-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "progressbar",
		},
	});

	primarySwiper.on("transitionEnd", function () {
		let index = primarySwiper.realIndex + 1;
		$(".primary-banner")
			.find(".index-slide")
			.text("0" + index);
	});
	primarySwiper.on("slideChange", function () {
		const videoElement =
			this.slides[this.activeIndex].querySelector("video");
		if (videoElement) {
			primarySwiper.autoplay.stop();
			videoElement.play();
			
		}
	});

	const videoElements = document.querySelectorAll(".primary-banner-video");
	videoElements.forEach(function (videoElement) {
		videoElement.addEventListener("ended", function () {
			primarySwiper.slideNext();
			primarySwiper.autoplay.start();
		});
		videoElement.addEventListener("click", function (event) {
			if (this.paused) {
				this.play();
				this.parentNode.classList.remove("pause");
			} else {
				this.pause();
				this.parentNode.classList.add("pause");
			}
		});
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	var galleryMenu = new Swiper(".menu-gallery-swiper  .swiper", {
		speed: 500,
		slideToClickedSlide: true,
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 10,
			},
			1024.1: {
				slidesPerView: 2,
				spaceBetween: 10,
				grid: {
					rows: 2,
				},
			},
		},
		loop: false,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".menu-gallery-swiper .nav-arrow-next",
			prevEl: ".menu-gallery-swiper .nav-arrow-prev",
		},
	});

	var topBanner = new Swiper(".partner-swiper  .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 3,
				spaceBetween: 10,
			},
			1024.1: {
				slidesPerView: 4,
				spaceBetween: 30,
			},
			1024.1: {
				slidesPerView: 6,
				spaceBetween: 30,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".partner-swiper .nav-arrow-next",
			prevEl: ".partner-swiper .nav-arrow-prev",
		},
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

//Tab active

function tabActive() {
	$('.tab-wrapper .tab-item:first-child').addClass('active')
	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}
